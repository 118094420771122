html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.c--anim {
  position: absolute;
  background-color: transparent;
  width: 30%;
  bottom: 0;
  left: 0;
  visibility: visible !important;
}
.s-cnt {
  background-color: bisque;
}
body {
  margin: 0 auto;
  padding: 16px 0;
}

.cc-btns {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.cc-btns > button {
  padding: 10px;
}

html:not(.cc--darkmode) #cc-main {
  --cc-overlay-bg: rgba(215, 221, 224, 0.761);
}

.cc-font-style {
  font-weight: bold;
  pointer-events: none;
}
