// heights with !important are used because of the late change to this css on the day of the release
// normally flow custom css should have been edited, but it has been decided that the risk is lower like this
// versus updating 10+ custom css rules for live flows on the day of the release - easier to track, history in git
// and easier to revert if something goes wrong

@import '../../styles/constants';

.sl-forgot-password {
  background-color: $white;
  color: $dark-blue;
  text-align: center;
  padding: 150px 40px 20px;
  font-weight: 500;
  box-sizing: border-box;
  min-height: calc(100vh - #{$footer-height}) !important;
  height: auto !important;

  @media screen and (max-width: $tablet-width) {
    min-height: calc(100vh - #{$footer-height}) !important;
    height: auto !important;
  }

  h1 {
    font-size: 34px;
    margin-bottom: 30px;
    line-height: 1;
  }

  h2 {
    font-size: 18px;
    line-height: 1.4;
  }

  &--top-content {
    max-width: $inner-content-width;
    background: #fff url(assets/header-trees.png) no-repeat center bottom;
    padding-bottom: 67px;
    border-bottom: 3px solid #ccc;
    margin: 0 auto;
  }
  &--loader {
    background: #fff url(assets/loader.gif) no-repeat center bottom;
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-bottom: -13px;
    padding: 10px;
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }

  &--content {
    max-width: $inner-content-width;
    padding: 30px 0;
    margin: 0 auto;

    label {
      font-size: 18px;
      text-align: left;
      display: block;
      padding-bottom: 10px;
    }
  }

  .sl-vertical-align {
    text-align: left;
    margin-bottom: 20px;
    div {
      display: inline-block;
      vertical-align: middle;
      width: calc(50% - 40px);
    }
    li {
      list-style: disc inside;
      font-size: 18px;
      line-height: 1.6;

      @media screen and (max-width: $mobile-width) {
        font-size: 16px;
      }
    }
  }

  .sl-button-wrapper {
    box-sizing: border-box;
  }

  .sl-button {
    border-radius: 24px;
    text-transform: none;
    width: 210px;
    &:hover {
      background-color: $white;
      border-color: $dark-blue;
      color: $dark-blue;
    }
  }

  .sl-container-text-input {
    &--has-error {
      .sl-input-text {
        border-color: $error-dark;
      }
    }
  }
  &--error,
  &--success {
    text-align: left;
    padding-bottom: 30px;
    font-size: 16px;
  }
  &--error {
    color: $error-dark;
  }
  &--success {
    color: $light-green;
    span {
      color: $dark-blue;
    }
  }
  .sl-password-tooltip {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: $light-blue;
    color: $white;
    cursor: pointer;
    .sl-password-tooltip-text {
      display: none;
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      width: 180px;
      padding: 5px 5px 5px 20px;
      border-radius: 10px;
      background-color: $light-blue;
      font-size: 14px;
      color: $white;
      text-align: left;
      @media screen and (max-width: 500px) {
        left: -185px;
      }
    }
    &:before {
      content: '?';
      z-index: 51;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 14px;
      width: 18px;
      padding: 3px 0 2px;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
    }
    @media screen and (max-width: $tablet-width-landscape) {
      &.visible {
        &:before {
          content: 'x';
        }
        .sl-password-tooltip-text {
          display: inline;
        }
      }
    }
    @media screen and (min-width: $tablet-width-landscape) {
      &:hover {
        .sl-password-tooltip-text {
          display: inline;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-forgot-password {
    .sl-vertical-align div {
      width: 100%;
      display: block;
    }

    .sl-button-wrapper {
      padding-left: 0;
    }

    .sl-button {
      margin: 20px auto 0;
    }
  }
}
