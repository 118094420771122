@import '../../../config/styles/constants';
@import '../../../config/styles/colors';

[data-new-authentication-component='true'] {
  label {
    font-size: $font-size-standard;
    margin-bottom: $margin-below-input-labels;
    line-height: 25px;
    display: inline-block;
    color: $sleepio_navy;
  }
  input {
    width: 100%;
    height: $input-height;

    padding: $input-padding;

    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    box-sizing: border-box;
    color: $dark-blue;

    font-size: $font-size-small;

    &:focus {
      // border: 1px solid $light-blue;
      outline: none;
    }

    &[data-error='true'] {
      border: 2px solid $red;
    }

    &:read-only {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .sl-input-text__error,
  .sl-login--error,
  .sl-forgot--error {
    color: $red;

    font-size: $font-size-extra-small;
    text-align: left;
  }
  .input-error {
    border-color: $red;
  }
  @media screen and (max-width: $max-form-width) {
    label {
      font-size: $font-size-small;
    }
  }
  .daylight {
    label {
      margin-left: 10px;
    }
    input {
      background-color: #fbfcfd;
      border: 1px solid #dfe1e6;
      border-radius: 8px;
      height: 70px;
      font-size: $font-size-extra-small;
      color: #363334;
    }
    .input-error {
      border: 1.5px solid $red;
    }
    .sl-input-text__error {
      background: none;
      padding: 0;
      border: none;
    }
  }
}

.input-container {
  margin-top: 24px;
  margin-bottom: 16px;
}
