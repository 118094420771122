$header-side-padding: 48px;
$header-side-padding-small: 35px;
$form-side-padding: 60px;
$form-top-padding: 40px;
$input-padding: 17px;
$very-small-padding: 6px;

$input-height: 55px;
$button-height: 55px;
$header-height: 80px;
$footer-height: 140px;

$max-form-width: 670px;
$max-button-width: 345px;
$very-small-device-with: 400px;

$margin-below-header: 8px;
$margin-below-inputs: 20px;
$margin-button: 36px;
$margin-below-social-button: 25px;
$margin-divider: 40px;
$margin-bottom-contact: 48px;
$margin-bottom-contact-small: 32px;
$margin-bottom-footer-text: 5px;
$margin-above-field-error: 8px;
$margin-below-input-labels: 8px;
$margin-below-forgot-password-helper-text: 20px;

$button-border-radius: 32px;
$form-border-radius: 0px;
$input-border-radius: 8px;

$font-size-form-title: 48px;
$font-size-form-title-small: 24px;
$font-size-standard: 20px;
$font-size-small: 18px;
$font-size-extra-small: 16px;

/* Investigate and see if we can delete */
$font-size-forgot-password: 20px;
$font-size-back-to-login: 20px;
$font-size-forgot-password-helper-text: 20px;
$font-size-label: 20px;
$font-size-input: 20px;
$font-size-button: 20px;
$font-size-footer: 20px;
$font-size-error-field: 16px;
