@import '../../../config/styles/colors';
@import '../../../config/styles/constants';

.sl-new-login-button-wrapper,
.sl-new-forgot-button-wrapper {
  position: relative;
  max-width: $max-button-width;
  margin: $margin-button auto;
  @media screen and (max-width: $max-form-width) {
    max-width: 247px;
  }
}

.sl-new-login-button,
.sl-new-forgot-button {
  background: $salmon;
  color: $dark-blue;
  border-radius: $button-border-radius;
  box-sizing: border-box;

  border: none;
  text-transform: none;

  padding: 16px 32px;
  text-align: center;
  display: block;

  -webkit-appearance: none;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;

  height: $button-height;

  position: relative;
  cursor: pointer;
  width: 100%;
  min-width: 192px;
  font-size: $font-size-button;

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  &__spinner_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__spinner {
    width: 30px;
    height: 30px;
    position: relative;

    .bar {
      width: 2px;
      height: 6px;
      background-color: white;
      position: absolute;
      top: 12px;
      left: 14px;
      transform-origin: center;
      animation: spin 1.2s linear infinite;
    }

    .bar:nth-child(1) {
      transform: rotate(0deg) translate(0, -10px);
      animation-delay: -1.1s;
    }

    .bar:nth-child(2) {
      transform: rotate(30deg) translate(0, -10px);
      animation-delay: -1s;
    }

    .bar:nth-child(3) {
      transform: rotate(60deg) translate(0, -10px);
      animation-delay: -0.9s;
    }

    .bar:nth-child(4) {
      transform: rotate(90deg) translate(0, -10px);
      animation-delay: -0.8s;
    }

    .bar:nth-child(5) {
      transform: rotate(120deg) translate(0, -10px);
      animation-delay: -0.7s;
    }

    .bar:nth-child(6) {
      transform: rotate(150deg) translate(0, -10px);
      animation-delay: -0.6s;
    }

    .bar:nth-child(7) {
      transform: rotate(180deg) translate(0, -10px);
      animation-delay: -0.5s;
    }

    .bar:nth-child(8) {
      transform: rotate(210deg) translate(0, -10px);
      animation-delay: -0.4s;
    }

    .bar:nth-child(9) {
      transform: rotate(240deg) translate(0, -10px);
      animation-delay: -0.3s;
    }

    .bar:nth-child(10) {
      transform: rotate(270deg) translate(0, -10px);
      animation-delay: -0.2s;
    }

    .bar:nth-child(11) {
      transform: rotate(300deg) translate(0, -10px);
      animation-delay: -0.1s;
    }

    .bar:nth-child(12) {
      transform: rotate(330deg) translate(0, -10px);
      animation-delay: 0s;
    }

    @keyframes spin {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.daylight {
  .sl-new-login-button,
  .sl-new-forgot-button {
    background-color: $daylight-button;
    color: $white;
    &:hover {
      opacity: 0.7;
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &:focus {
      opacity: 0.7;
      outline: 0;
    }
  }
}
