@import '../config/styles/constants';
@import '../config/styles/colors';

[data-new-authentication-component='true'] {
  background-color: $pale_gray;

  #full-site-loading-spinner {
    background-image: url('img/throbber-moon-transparent@2x.gif');
    background-color: $dark-blue;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    display: none;

    &[data-visible='true'] {
      display: block;
    }
  }

  #sl-login-page {
    height: 100%;
    #sl-login {
      flex-grow: 1;
      background-color: transparent;
      .sl-new-login--forgot,
      .sl-new-login--forgot:visited {
        text-align: left;
        text-decoration: underline;
        font-size: 18px;
        color: $sleepio_navy;
      }
      .sl-login--error {
        padding: none;
      }
      .sl-login-nhs--error {
        padding: none;
        align-self: center;
        padding-bottom: 10px;
      }
      .sl-new-login--contact {
        color: $primary-navy;
        font-size: $font-size-standard;
        text-align: center;
        a {
          color: $primary-navy;
        }
      }
      .sl-new-login--country-text {
        color: $primary-navy;
        font-size: $font-size-standard;
        text-align: center;
        align-self: center;
        padding-top: 50px;
        padding-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: $max-form-width) {
    #sl-login-page {
      padding: 0;

      h1 {
        display: none;
      }

      #sl-new-facebook-sso {
        margin-top: $margin-below-social-button;
        margin-bottom: $margin-above-field-error;
      }

      form#sl-login {
        .sl-new-login--forgot,
        .sl-new-login--forgot:visited {
          font-size: $font-size-extra-small;
        }
        #dividing-line {
          display: none;
        }
      }
    }
  }
}
