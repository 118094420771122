.nhslogin-button {
  font-size: 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 28px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #005eb8;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 4px 0 #003087;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-top: 0;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: auto;
  align-self: center;
}
@media (min-width: 40.0625em) {
  .nhslogin-button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}
@media print {
  .nhslogin-button {
    font-size: 14pt;
    line-height: 1.15;
  }
}
@media (min-width: 40.0625em) {
  .nhslogin-button {
    margin-bottom: 36px;
  }
}
@media (max-width: 40.0525em) {
  .nhslogin-button {
    padding: 8px 16px;
  }
}
.nhslogin-button:link,
.nhslogin-button:visited,
.nhslogin-button:active,
.nhslogin-button:hover {
  color: #fff;
  text-decoration: none;
}
.nhslogin-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.nhslogin-button:hover {
  background-color: #1e569b;
}
.nhslogin-button:focus {
  background: #ffeb3b;
  box-shadow: 0 4px 0 #003087;
  color: #003087;
  outline: none;
}
.nhslogin-button:active {
  background: #1e569b;
  box-shadow: none;
  color: #fff;
  top: 4px;
}
.nhslogin-button::before {
  background: transparent;
  bottom: -6px;
  content: '';
  display: block;
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
}
.nhslogin-button:active::before {
  top: -6px;
}
.nhslogin-button--reverse {
  background-color: #fff;
  box-shadow: 0 4px 0 #003087;
  color: #005eb8;
}
.nhslogin-button--reverse:hover {
  background-color: #f2f2f2;
  color: #005eb8;
}
.nhslogin-button--reverse:focus {
  background: #ffeb3b;
  box-shadow: 0 4px 0 #003087;
  color: #003087;
  outline: 4px solid transparent;
}
.nhslogin-button--reverse:active {
  background: #f2f2f2;
  box-shadow: none;
  color: #005eb8;
  top: 4px;
}
.nhslogin-button--reverse:link {
  color: #005eb8;
}
.nhslogin-button--reverse.nhsuk-button--disabled {
  background-color: #fff;
}
.nhslogin-button--reverse.nhsuk-button--disabled:focus {
  background-color: #fff;
}
.nhslogin-button--disabled,
.nhslogin-button[disabled='disabled'],
.nhslogin-button[disabled] {
  background-color: #005eb8;
  opacity: 0.5;
  pointer-events: none;
}
.nhslogin-button--disabled:hover,
.nhslogin-button[disabled='disabled']:hover,
.nhslogin-button[disabled]:hover {
  background-color: #005eb8;
  cursor: default;
}
.nhslogin-button--disabled:focus,
.nhslogin-button[disabled='disabled']:focus,
.nhslogin-button[disabled]:focus {
  background-color: #005eb8;
  outline: none;
}
.nhslogin-button--disabled:active,
.nhslogin-button[disabled='disabled']:active,
.nhslogin-button[disabled]:active {
  box-shadow: 0 4px 0 #003087;
  top: 0;
}
.nhslogin-button--reverse[disabled='disabled'],
.nhslogin-button--reverse[disabled] {
  background-color: #fff;
  opacity: 0.5;
}
.nhslogin-button--reverse[disabled='disabled']:hover,
.nhslogin-button--reverse[disabled]:hover {
  background-color: #fff;
  cursor: default;
}
.nhslogin-button--reverse[disabled='disabled']:focus,
.nhslogin-button--reverse[disabled]:focus {
  outline: none;
}
.nhslogin-button--reverse[disabled='disabled']:active,
.nhslogin-button--reverse[disabled]:active {
  box-shadow: 0 4px 0 #003087;
  top: 0;
}
.ie8 .nhslogin-button:focus {
  outline: 1px dotted #003087;
}
.nhslogin-button-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-logo {
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
