@import '../../styles/constants';

.sl-options-wrapper {
  margin-bottom: 13px;

  .sl-option-row {
    margin-bottom: 12px;
    user-select: none;

    &:last-child {
      margin-bottom: 0;
    }

    &--checked {
      .sl-option {
        background-color: $white;
        box-shadow: $component-box-shadow;

        &:before {
          content: '\2713';
          background-color: $dark-blue;
          font-size: 14px;
          text-align: center;
        }

        @media speech, aural, reader {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  .sl-option {
    display: block;
    position: relative;
    cursor: pointer;
    background-color: $lighter-blue;
    color: $dark-blue;
    border: 0;
    border-radius: 3px;
    padding: $component-padding;
    padding-right: 32px;
    margin: $component-margin;
    line-height: 1;
    font-weight: 500;
    box-sizing: border-box;

    &[data-focused='true'] {
      box-shadow: 0 0 2px 2px #ffffff;
      outline: 0;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 18px;
      color: $white;
      border-radius: 10px;
      background-color: $dark-blue;

      @media speech, aural, reader {
        display: none;
        visibility: hidden;
      }
    }
  }

  &.sl-answer-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    .sl-option-row {
      padding-right: 2px;
      max-width: 160px;
      box-sizing: border-box;
      flex: 1 1 0;

      .sl-option {
        background-color: $box-answer;
        border-radius: 0;
        padding: 14px 12px;
        text-align: center;
        line-height: 23px;

        span {
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:before {
          display: none;
        }
      }

      &--checked {
        .sl-option {
          background-color: $box-answer-selected;
        }
      }
    }
  }
}

[data-card-type='true'] {
  .sl-options-wrapper {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .sl-option-row {
      width: 230px;
      margin-bottom: 30px;

      .sl-option {
        height: 320px;

        padding: 15px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        white-space: pre-line;

        img {
          max-height: 50%;
          max-width: 100%;
          object-fit: contain;
        }

        .card-top-text,
        .card-bottom-text {
          text-align: center;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}

.sl-box-limits-text {
  width: 100%;
  margin-bottom: 10px;

  span {
    opacity: 40%;
    width: 50%;
    display: inline-block;

    &:last-child {
      text-align: right;
    }
  }
}

.sl-container-radio-options--error {
  color: $error;
  font-size: 18px;
  margin-top: -5px;
}

@media screen and (max-width: 550px) {
  [data-card-type='true'] {
    .sl-options-wrapper {
      .sl-option-row {
        width: 143px;

        label.sl-option {
          height: 220px;
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  [data-card-type='true'] {
    .sl-options-wrapper {
      .sl-option-row {
        margin: 10px auto;
      }
    }
  }
}
