@import '../../styles/constants';

.sl-empty-layout {
  overflow: hidden;
}

.sl-token-reset-page {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  text-align: left;
  padding: 0 55px;
  box-sizing: border-box;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  height: 100vh;

  &__inner {
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 100%;
  }

  h2 {
    letter-spacing: 2px;
    font-size: 40px;
    line-height: 51px;
    margin-bottom: 10px;
  }

  h3 {
    letter-spacing: 0.5px;
    font-size: 35px;
    order: 1;
  }

  h4 {
    font-weight: 500;
    font-size: 35px;
    line-height: 110%;
    order: 3;
  }

  &.daylight {
    h3 {
      color: $black;
      font-size: 32px;
    }

    h4 {
      color: $black;
      font-size: 22px;
    }

    img {
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      order: 2;
    }
  }

  img {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    order: 2;
  }

  a {
    color: #1cddff;
    text-decoration: none;

    &:hover {
      color: #16a2bb;
    }
  }
}

@media screen and (max-height: 700px) {
  .sl-token-reset-page {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    text-align: left;
    width: 50%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .sl-token-reset-page.sleepio {
    position: relative;
    padding: 110px 55px 0 55px;
    top: 0;
    left: 0;
    transform: none;
    min-height: 650px;
  }
}

@media screen and (max-height: 600px) {
  .sl-token-reset-page.daylight {
    position: relative;
    padding: 110px 55px 0 55px;
    top: 0;
    left: 0;
    transform: none;
    min-height: 650px;
  }
}

@media screen and (min-width: 550px) and (max-height: 700px) {
  [data-org='check-new-email'][data-product='sleepio'] {
    .sl-empty-layout {
      footer {
        position: relative;
        bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 550px) and (max-height: 600px) {
  [data-org='check-new-email'][data-product='daylight'] {
    .sl-empty-layout {
      footer {
        position: relative;
        bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .sl-token-reset-page {
    width: 80%;
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-header__content {
    .sl-header__logo {
      display: block;
    }
  }

  .sl-token-reset-page {
    padding: 0;
    width: 90%;

    &.daylight {
      h3 {
        color: $black;
        font-size: 24px;
      }

      h4 {
        color: $black;
        font-size: 18px;
      }
    }

    h3 {
      font-size: 14px;
    }

    h4 {
      order: 2;
      font-size: 22px;
    }

    img {
      order: 3 !important;
    }
  }
}

@media screen and (max-width: $tablet-width) and (max-height: 700px) {
  .sl-token-reset-page.sleepio,
  .sl-token-reset-page.daylight {
    padding: 90px 55px 90px 55px;
    min-height: auto !important;
  }
}

@media screen and (max-width: 550px) and (max-height: 700px) {
  .sl-token-reset-page.sleepio,
  .sl-token-reset-page.daylight {
    padding: 90px 30px 90px 30px;
    min-height: auto !important;
  }
}
