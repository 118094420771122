// can be removed after we switch to themed component 100% (Ticket: ENR-2331)
@import '../../styles/constants';

.sl-radio-wrapper {
  .sl-radio {
    margin-bottom: 10px;
    label {
      cursor: pointer;
      font-size: 12px;
    }
    input {
      margin: -4px 10px 0 5px;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  &::after {
    clear: both;
    content: '';
    display: block;
  }

  .sl-radio-items--has-error {
    color: $error;
  }
}
