@import '../../styles/constants';
@import '../../styles/mixins';

.sl-page__item-one-line {
  .sl-page-title-area {
    width: 40%;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    .sl-page-pre-question {
      margin-bottom: 10px;
      line-height: 1;
      font-size: 15px;
    }
    .sl-page-title {
      font-size: 16px;
      text-align: left;
      margin-bottom: 0;
      line-height: 1;
      display: inline-block;
    }
    .sl-page-post-question {
      margin-top: 10px;
      line-height: 1;
      font-size: 15px;
    }
    &--full {
      width: 100%;
    }
  }
  .sl-question-answer {
    display: inline-block;
    vertical-align: top;
    width: 60%;

    .sl-error {
      font-size: 15px;
    }

    .sl-select-wrapper {
      display: block;
      .sl-select {
        padding: 10px 30px 10px 17px;
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 2%;
        width: 98%;
      }
    }

    .sl-radio {
      display: inline-block;
      text-align: center;
      font-size: 13px;
      margin-bottom: 0;
      float: left;
      span {
        display: block;
      }
    }

    .sl-page-post-answer a {
      white-space: nowrap;
      text-decoration: underline;
    }
    &--full {
      margin-top: 10px;
      width: 100%;
    }
  }
  .sl-input-text,
  .sl-input-number {
    font-size: 15px;
  }
  .sl-input-tooltip {
    top: 13px;
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-page__item-one-line {
    .sl-page-title-area {
      width: 100%;
      .sl-page-pre-question {
        font-size: 14px;
      }
      .sl-page-post-question {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 10px;
      }
      .sl-page-title {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .sl-question-answer {
      width: 100%;
      .sl-select-wrapper {
        .sl-select {
          width: 100%;
          margin-left: 0;
          vertical-align: unset;
        }
      }
      .sl-error {
        padding: 0;
      }
    }
  }
}

.sl-question.autocomplete {
  position: relative;
}

.sl-question.side-by-side {
  $side-by-side-card-height: 155px;

  .sl-options-wrapper {
    clear: both;
  }

  .sl-options-wrapper .sl-option-row {
    width: 49%;
    min-width: inherit !important;
    display: inline-block;

    label {
      height: $side-by-side-card-height;
      line-height: $side-by-side-card-height;
      padding: 0 60px;
      border-radius: 8px;

      &:before {
        opacity: 0;
      }

      input {
        opacity: 0;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 25px;
      }
    }

    &:first-of-type {
      margin-right: 2%;
      float: left;
    }
  }

  .sl-container-radio-options--error {
    float: left;
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-question.side-by-side {
    .sl-options-wrapper .sl-option-row {
      float: none;
      width: 100%;
    }
  }
}
