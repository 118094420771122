@import '../../styles/constants';

.country-code-select {
  box-sizing: border-box;

  label {
    display: none;
  }

  &__wrapper {
    @media screen and (max-width: $mobile-width-large) {
      width: 285px;
    }

    border-radius: 8px;
    border: 1px solid $input-outline-gray;
    padding: 24px;
    width: 240px;
    font-size: 16px;

    &--error {
      border: 1px solid $daylight-error-red;
    }

    &--disabled {
      color: $legally_gray;
    }
  }

  &__message {
    visibility: hidden;

    &--error {
      padding-top: 4px;
      visibility: visible;
      color: $daylight-error-red;
    }
  }
}
