.sl-interactive--checkbox {
  flex-flow: row wrap;
  display: flex;

  &[data-hidden='true'] {
    input {
      display: none;
    }
  }

  label,
  input {
    cursor: pointer;
  }

  label {
    font-size: 18px;
    text-overflow: unset !important;
    white-space: normal !important;
    display: inline !important;
    flex: 1 0;
    order: 2;
  }

  input {
    margin-left: 0;
    display: inline-block;
    order: 1;
    flex: 0 auto;
  }

  .sl-input-checkbox__error {
    order: 3;
    flex: 1 100%;
  }

  a {
    text-decoration: underline;
  }
}
