#sl-virgin-pulse-sso {
  position: relative;
  display: block;
  vertical-align: middle;
  width: 200px;
  padding: 15px 0 15px 60px;
  font-size: 16px;
  font-weight: 700;
  color: #555;
  margin: 16px auto 0;
  text-align: left;
  text-decoration: none;
  box-shadow: 0 2px 4px 1px rgba(85, 85, 85, 0.34);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  border: 1px solid #c1c1c1;
  cursor: pointer;
  background: #fff url(./img/virginpulse-sso-logo.png) 15px 11px no-repeat;
  background-size: 30px 30px;
}
