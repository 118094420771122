@import '../../config/styles/constants';
@import '../../config/styles/colors';

#sl-header {
  height: $header-height;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  padding: 0 $header-side-padding;
  box-sizing: border-box;

  #sl-header-sign-up,
  #sl-header-log-in {
    color: $sleepio_navy;
    font-size: $font-size-standard;
    font-weight: 500;
    text-decoration: none;
  }

  #sl-header-sign-up {
    border: 1px solid $sleepio_navy;
    padding: 8px 40px;
    border-radius: 40px;
  }
}

@media screen and (max-width: 768px) {
  #sl-header {
    padding: 0 $header-side-padding-small;
    margin: -8px;
    #sl-header-sign-up {
      padding: 8px 20px;
      border-radius: 20px;
    }
    #sl-header-log-in,
    #sl-header-sign-up {
      margin-right: 32px;
    }
  }
}

@media screen and (max-width: $max-form-width) {
  #sl-header {
    padding: 0 8px;
    margin: 0px;
    a {
      color: $sleepio_navy;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  height: 42px;
  width: 115px;
}

.daylight {
  #sl-header {
    background-color: $daylight-background;
  }
  .logo {
    width: 100%;
    padding-left: 24px;
  }
  .logo-image {
    width: 114px;
    height: 57px;
  }
  @media screen and (max-width: $max-form-width) {
    #sl-header {
      background-color: $white;
    }
    .logo-image {
      width: 64px;
      height: 32px;
    }
  }
}
