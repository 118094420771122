@import '../../styles/constants';

.sl-container-text-input {
  &--has-error {
    .sl-input-number {
      border: 2px solid $error;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.sl-input-number {
  width: 100%;
  box-sizing: border-box;
  margin: $component-margin;
  border: 1px solid $light-blue;
  border-radius: $border-radius;
  padding: $component-padding;
  color: $dark-blue;
  font-weight: 500;

  &:focus {
    box-shadow: 0 0 2px 2px $light-blue;
  }
}

::-webkit-input-placeholder {
  color: #8e8e8e;
}
:-ms-input-placeholder {
  color: #8e8e8e !important;
}
::-ms-input-placeholder {
  color: #8e8e8e;
}
