@import '../../styles/constants';

.sl-report {
  .sl-arrow-box {
    position: relative;
    background: #30c3e6;
    padding: 20px;
    text-align: center;
    line-height: 30px;
    font-size: 36px;
    border-radius: 20px;
    width: 50%;
    margin: 0 auto 50px !important;
    color: $black;
    &:after {
      top: 100%;
      left: 50%;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: 30px solid transparent;
      border-top-color: #30c3e6;
      margin-left: -30px;
    }
  }

  .sl-report-logo {
    display: none !important;
  }
  ul {
    padding-left: 30px;
    li {
      list-style-type: disc;
      font-size: 15px;
    }
  }
  .sl-report-unsubscribe {
    display: none;
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-arrow-box {
    line-height: 20px !important;
    width: 60% !important;
    .sl-score {
      font-size: 28px !important;
    }
    &:after {
      border-width: 20px;
      margin-left: -20px;
    }
  }
  .sl-tailor {
    width: 200px !important;
    font-size: 16px !important;
  }
}
