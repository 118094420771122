@import '../../styles/constants';

.sl-button-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.sl-button {
  background: $white;
  color: $dark-blue;
  border: 2px solid $white;
  border-radius: 30px;
  box-sizing: border-box;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 24px 14px 24px;
  text-align: center;
  transition: all 0.5s;
  -webkit-appearance: none;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  user-select: none;
  margin: $component-margin;
  min-width: 247px;

  &--wide {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  &--green {
    background: $light-green;
    border-color: $light-green;

    &:hover {
      background: $dark-green;
      border-color: $dark-green;
    }
  }

  &--blue {
    background: #036;
    border-color: #fff;
    color: #fff;

    &:hover {
      background: #fff;
      border-color: #036;
      color: #036;
    }
  }

  &:focus {
    box-shadow: 0 0 2px 2px #ffffff;
    outline: 0;
  }

  &__checkmark {
    position: absolute;
    display: block;
    top: 44%;
    right: 31%;

    @media screen and (max-width: $mobile-width-large) {
      top: 39%;
    }
  }

  &__loading {
    color: $dark-blue;
    display: inline-block;
    width: 32px;
    height: 32px;

    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__loading div {
    transform-origin: 16px 16px;
    animation: sl-spinner 1.2s linear infinite;
  }

  &__loading {
    div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 22px;
      left: 15px;
      width: 2px;
      height: 6px;
      border-radius: 20%;
      background: #fff;
    }

    div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

@keyframes sl-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

a.sl-button[data-sl-next='true'] {
  display: inline-block;
}

@media screen and (max-width: $tablet-width) {
  .sl-button__loading {
    right: 5px;
    top: 23px;
  }
}
