@import '../config/styles/constants';
@import '../config/styles/colors';

[data-new-authentication-component='true'] {
  #sl-forgot-page {
    #sl-forgot {
      .sl-new-login--login {
        display: none;
      }
    }
  }
  #sl-reset-page,
  #sl-forgot-page {
    h1,
    p {
      text-align: center;
    }

    h1 {
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: $max-form-width) {
  .sl-tooltip-text {
    position: absolute;
    right: -50px;
  }
}

.forgot-email {
  padding-top: 20px;
  font-size: $font-size-standard;
  @media screen and (max-width: $max-form-width) {
    font-size: $font-size-small;
  }
}
