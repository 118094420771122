@import '../../styles/constants.scss';

.sl-container-text-input {
  &--has-error {
    .sl-input-text {
      border: 2px solid $error;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &--has-tooltip {
    position: relative;
    z-index: 2;
    opacity: 1;
    transform: translateZ(0);

    &.sl-tooltip-image {
      .sl-tooltip-text {
        &:before {
          margin-top: 11px;
        }
        width: 250px;
        a {
          color: $dark-blue;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .sl-input-text {
      padding-right: 38px;
    }
  }
  .sl-employee-tooltip-image {
    text-align: center;
    img {
      max-width: 240px;
      margin: 5px;
    }
  }

  .sl-autocomplete-suggestions {
    background: $white;
    padding: 0;
    border-radius: 3px;
    margin-top: 5px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 9;

    li {
      color: $footer-blue;
      padding: 10px 20px;
      font-size: 16px;

      &.hoverMouse {
        background: $lighter-blue;
        cursor: pointer;
      }

      &.hover {
        background: $lighter-blue;
        cursor: pointer;
      }

      &[data-default='true'] {
        font-weight: bolder;
        border-top: 1px solid $light-gray;
      }
    }
  }
}

.sl-show-hide-password {
  cursor: pointer;
  text-align: right;
  font-size: 14px;
}

.sl-input-text {
  width: 100%;
  box-sizing: border-box;
  margin: $component-margin;
  border: 1px solid $light-blue;
  border-radius: $border-radius;
  padding: $component-padding;
  color: $dark-blue;

  &:focus {
    box-shadow: 0 0 2px 2px $light-blue;
  }

  &__error {
    color: $error;
    margin-top: 5px;
    font-size: 18px;
  }
}

::-webkit-input-placeholder {
  color: #8e8e8e;
}

:-ms-input-placeholder {
  color: #8e8e8e !important;
}

::-ms-input-placeholder {
  color: #8e8e8e;
}

.sl-input-tooltip {
  position: absolute;
  right: 10px;
  top: 16px;
  background-color: $dark-blue;
  color: $white;
  border-radius: 20px;
  padding: 3px;
  cursor: pointer;
  font-size: 17px;
  .sl-question-mark {
    padding: 0 5px;
  }
  .sl-tooltip-text-wrapper {
    display: none;
    position: absolute;
    right: 0;
    .sl-close-tooltip {
      display: none;
    }
    .sl-tooltip-text {
      margin-top: 3px;
      background-color: $light-blue;
      font-size: 14px;
      padding: 10px;
      border-radius: 10px;
      color: $white;
      z-index: -1;
    }
  }

  &:hover {
    .sl-tooltip-text-wrapper {
      display: block;
      z-index: 99;
      .sl-tooltip-text {
        display: block;
        transition-delay: 0s;
        z-index: 2;
      }
    }
  }
}

div[data-disabled='true'] {
  .sl-input-tooltip {
    &:hover {
      .sl-tooltip-text-wrapper {
        display: none;
      }
    }
  }
}

.sl-interactive--employee-id {
  .sl-container-text-input--has-tooltip {
    z-index: 3;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

@media screen and (max-width: $tablet-width) {
  .sl-input-tooltip {
    .sl-tooltip-text-wrapper {
      display: none;
      .sl-close-tooltip {
        display: block;
        margin-right: 2px;
        position: absolute;
        right: 3px;
        color: $dark-blue;
        top: 2px;
      }
      .sl-tooltip-text {
        .sl-employee-tooltip-text {
          padding-top: 7px;
        }
      }
    }
    &.active {
      .sl-tooltip-text-wrapper {
        display: block;
      }
    }
  }
}
