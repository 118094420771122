@import '../../styles/constants';

.sl-header {
  height: $header-height;
  background: $header-blue;
  position: fixed;
  width: 100%;
  z-index: 10;
  &__content {
    position: relative;
    max-width: $max-content-width;
    margin: 0 auto;
    height: inherit;
    user-select: none;
  }

  &__logo {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 36px;
    img {
      width: 100%;
    }
  }

  &__tracker {
    margin: 0;
    padding: 0;
    line-height: $header-height;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  &__login {
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 9;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 12px;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  .sl-login {
    position: absolute;
    right: 20px;
    top: calc(50% + 17px);
    z-index: 3;
    .sl-button__loading {
      right: 10px;
    }
  }

  &--opened {
    .sl-header__login {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $light-blue;
      border: 1px solid $dark-blue;
      border-bottom: none;
      color: $dark-blue;
      font-weight: 500;
      &:hover {
        color: $black;
      }
    }
    .sl-header__overlay {
      display: block;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: none;
  }
  .loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #3498db; /* Blue */
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-header {
    height: $header-height-tablet;

    &__logo {
      display: none;
    }

    &__text {
      line-height: $header-height-tablet;
    }
    .logging-out span {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .sl-header__text {
    font-size: 20px;
  }
  .sl-header__login,
  .sl-header .sl-login {
    right: 5px;
  }
}

@media screen and (max-width: $mobile-width) {
  .sl-header__text {
    font-size: 18px;
  }
}
@media screen and (max-width: 320px) {
  .sl-header__text {
    font-size: 16px;
  }
  .sl-header__login {
    font-size: 15px;
    right: 3px;
  }
}
/* fix for mobile landscape inputs not reachable */
@media screen and (max-height: $mobile-height) {
  .sl-header {
    position: relative;
  }
}
