@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Ultra-Light.woff') format('woff'); // All modern browsers support woff (as well as IE11)
  font-weight: 100;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Ultra-Light-Oblique.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Thin.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Thin-Oblique.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Light-Oblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400; //same as font-weight: normal;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Regular-Oblique.woff') format('woff');
  font-weight: 400; //same as font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Medium-Oblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Bold.woff') format('woff');
  font-weight: 700; // same as font-weight: bold;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Bold-Oblique.woff') format('woff');
  font-weight: 700; // same as font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Black.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Black-Oblique.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Ultra-Bold.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: GT-Walsheim;
  src: url('../fonts/GT-Walsheim-Ultra-Bold-Oblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
