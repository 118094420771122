@import '../../../../styles/constants';
.sl-interactive-v2 {
  .sl-interactive--sso {
    width: 100%;

    a {
      text-align: center !important;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      box-sizing: border-box;
      background-position-x: 25px !important;
    }
  }
}
@media screen and (max-width: $small-mobile-width) {
  .sl-interactive-v2 {
    .sl-interactive--sso {
      a {
        background-position-x: 10px !important;
      }
    }
  }
}
