@import '../config/styles/constants';
@import '../config/styles/colors';

[data-new-authentication-component='true'] {
  a {
    color: $sleepio_navy;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: $sleepio_navy;
    }
  }

  #sl-new-login--login {
    font-size: $font-size-back-to-login;
    text-decoration: none;
  }

  #contact-email {
    color: $white;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: $white;
    }
  }

  .reset-password-hints {
    margin-bottom: 40px;

    .sl-input-tooltip {
      position: relative;
      right: auto;
      top: auto;
      font-size: $font-size-standard;
      padding: 0;
      .sl-tooltip-text-wrapper {
        margin-top: 5px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 90%;
          width: 0;
          height: 0;
          border-bottom: 10px solid white;
          border-top: 10px solid transparent;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }

        .sl-tooltip-text {
          background-color: white;
          color: #003366;
          font-size: $font-size-standard;
          width: 300px;
          text-align: left;
        }
      }
    }
  }

  #password-suggestion {
    font-size: $font-size-small;
    @media screen and (max-width: $max-form-width) {
      font-size: $font-size-extra-small;
    }

    p:first-child {
      margin-bottom: 10px;
    }

    ul li {
      margin-bottom: 10px;
    }
  }
}

.save-button-margin-top {
  margin-top: 17px;
}

.daylight {
  .reset-password-hints {
    color: #6a717d;
  }
}

.reset-email {
  font-size: $font-size-standard;
  @media screen and (max-width: $max-form-width) {
    font-size: $font-size-small;
  }
}

.check-success {
  text-align: center;
  margin: 24px;
  p {
    font-size: $font-size-small;
  }
  a {
    font-size: $font-size-small;
  }
}
