@import './constants.scss';

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin inner-content {
  margin: 0 auto;
  padding: 0 $inner-content-padding;
  width: $inner-content-width;
  max-width: 100%;
  box-sizing: border-box;
}
