@use 'sass:math';

@import '../../config/styles/constants';
@import '../../config/styles/colors';

[data-new-authentication-component='true'] {
  #app,
  .sl-flow {
    height: calc(100% - #{$header-height});
  }

  [id*='sl-'][id*='-page'] {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    overflow: auto;

    display: flex;
    flex-direction: column;

    line-height: 1.35;

    [class*='sl-'][class*='--error'] {
      color: $red;
    }

    * {
      font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
      font-weight: 500;
    }

    h1 {
      margin: 20px 0;
      font-size: $font-size-form-title;
      font-weight: 400;
    }

    #sl-login,
    #sl-forgot,
    #sl-reset {
      /* ie 11 hack fo flexbox */
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      min-height: fit-content;
    }

    #sl-forgot,
    #sl-forgot-success,
    #sl-login,
    #sl-reset {
      border-radius: $form-border-radius;

      width: 100%;
      max-width: $max-form-width;

      margin: 0 auto auto;
      padding: $form-top-padding $form-side-padding;
      box-sizing: border-box;
      color: $sleepio_navy;

      h1 {
        margin-bottom: $margin-below-header;
      }

      & > p {
        font-size: $font-size-standard;
        letter-spacing: normal;
        margin-bottom: $margin-below-forgot-password-helper-text;
      }

      #contact-email {
        color: $sleepio_navy;
      }

      .sl-new-login--login,
      .sl-new-login--forgot,
      .sl-account--create {
        color: $white;
        font-size: $font-size-standard;
        text-decoration: none;
        display: block;

        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: $white;
          text-decoration: none;
        }
      }
      .sl-new-login--forgot,
      .sl-account--create {
        text-align: center;
        padding-bottom: 15px;
      }

      & > :last-child {
        margin-bottom: 0;
      }

      #dividing-line {
        height: 0;
        border: 1px solid $white;
        display: block;
        background: white;
        width: 100%;
        margin: $margin-divider 0;
      }
    }

    #sl-forgot-success {
      max-width: 780px;
    }

    ::-webkit-input-placeholder {
      color: $placeholder-color;
      font-size: $font-size-extra-small;
    }

    :-ms-input-placeholder {
      color: $placeholder-color;
      font-size: $font-size-extra-small;
    }

    #sl-new-footer {
      height: 141px;
      min-height: 141px;
      font-size: $font-size-small;
      margin: 0 40px 23px 40px;
      color: $legally_gray;
      justify-content: center;

      .sl-new-footer--contact {
        color: $sleepio_navy;
        font-size: $font-size-standard;
        margin-bottom: $margin-bottom-contact;
      }

      div {
        margin-bottom: $margin-bottom-footer-text;
        text-align: center;

        .product-name {
          text-transform: capitalize;
        }
      }
    }
    #sl-new-footer {
      &.sl-new-footer-short {
        height: 114px;
        min-height: 114px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    [id*='sl-'][id*='-page'] {
      padding: 0 $header-side-padding-small;
      #sl-new-footer {
        .sl-new-footer--contact {
          margin-bottom: $margin-bottom-contact-small;
        }
      }
    }
  }

  @media screen and (max-width: $max-form-width) {
    [id*='sl-'][id*='-page'] {
      padding: 0;

      form#sl-forgot,
      form#sl-login,
      form#sl-reset {
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: $max-form-width) {
    [id*='sl-'][id*='-page'] {
      padding: 0;

      #sl-forgot,
      #sl-forgot-success,
      #sl-login,
      #sl-reset {
        padding-left: 24px;
        padding-right: 24px;
        h1 {
          font-size: $font-size-form-title-small;
          text-align: left;
        }
        p {
          font-size: $font-size-small;
          text-align: left;
        }
      }
    }
  }
}

.login-component-container {
  height: 100%;
}

.daylight {
  [id*='sl-'][id*='-page'] {
    background-color: $daylight-background;
    color: $daylight-text;
    a {
      color: $daylight-link;
      font-weight: bold;
    }
    #sl-forgot,
    #sl-login,
    #sl-reset {
      padding-top: 20px;
      padding-bottom: 40px;
      background-color: $white;
    }
    @media screen and (max-width: $max-form-width) {
      background-color: $white;
    }
  }
}
