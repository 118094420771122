@import '../../styles/constants';

.sl-error-page {
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 42%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 0 55px;
  box-sizing: border-box;

  &__inner {
    padding: 0 55px;
  }

  h2 {
    letter-spacing: 2px;
    font-size: 40px;
    line-height: 51px;
    margin-bottom: 10px;
  }

  h3 {
    letter-spacing: 0.5px;
    font-size: 20px;
    margin-bottom: 28px;
  }

  h4 {
    font-weight: 500;
  }

  a {
    color: #1cddff;
    text-decoration: none;
    &:hover {
      color: #16a2bb;
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-error-page {
    top: 46%;
    padding: 0;
  }
}
