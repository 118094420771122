@import '../../styles/constants';

.sl-page {
  margin-top: 50px;
  margin-bottom: $header-height;

  &__item {
    margin: 0 0 70px;
  }

  &__item-one-line {
    margin: 0;
    border-top: 1px solid #ccc;
    padding-bottom: 26px;
    padding-top: 28px;
    .sl-page-post-answer {
      margin-top: 15px;
      text-align: center;
      font-size: 18px;
    }
  }

  &-title-area {
    margin-bottom: 29px;
  }

  &-title {
    font-size: 30px;
    text-align: left;
    line-height: 35px;
    margin-bottom: 35px;
  }

  &-pre-question {
    line-height: 1.2;
    margin-bottom: 10px;
  }

  &-post-question {
    line-height: 1.2;
    margin-top: 10px;
  }

  &-post-answer {
    text-align: center;
    margin-top: 29px;
    line-height: 1.2;
  }

  .sl-button-wrapper {
    margin-top: 20px;
  }

  .sl-general-error {
    // this is being added back in to account for static components relying on this exact class name combo (.sl-page .sl-general-error -- see flow bh2022/154
    color: $error;
    text-align: center;
    padding: 30px 0;
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-page {
    margin-top: 25px;
    margin-bottom: $header-height-tablet;

    &__item {
      margin: 0 0 32px;
    }
    &__item-one-line {
      .sl-page-post-answer {
        font-size: 15px;
        margin-top: 20px;
      }
    }

    &-title {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 25px;
    }

    &-title-area {
      margin-bottom: 20px;
    }
  }
}
