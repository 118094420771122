@import '../../styles/constants';

.sl-button.ttd__submit {
  min-width: 200px;
  height: 56px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  background: $daylight-button;
  color: white;
  margin-left: 16px;

  @media screen and (max-width: $mobile-width-large) {
    margin: 0;
  }

  &--success {
    background: $daylight-success-green;
  }
}

.ttd {
  &__component {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    @media screen and (max-width: $mobile-width-large) {
      justify-content: center;
    }
  }

  &__country-phone-wrapper {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;

    @media screen and (max-width: $mobile-width-large) {
      justify-content: center;
    }

    label {
      display: none;
    }
  }

  /* SMS consent is to be used later when we start supporting the feature */
  // &__consent {
  //   display: flex;
  //   font-weight: 500;
  //   margin: 16px 0 24px;

  //   label {
  //     margin-left: 8px;

  //     @media screen and (max-width: $mobile-width-large) {
  //       width: 295px;
  //     }
  //   }
  // }
}

.themed_ttd__component {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #d4e6ff;
  justify-content: center;
  margin-top: 10px;
  align-items: flex-start;
  padding: 20px 20px 0 20px;
}

@media screen and (max-width: 475px) {
  .themed_ttd__component {
    padding-top: 20px;
  }
}

.themed_ttd__country-phone-wrapper {
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  flex-wrap: wrap;
}
.themed_ttd__submit--success {
  background-color: #008275 !important;
  border-color: #008275 !important;
  color: #ffffff !important;
}
#send,
.sl-button.themed_ttd__submit {
  /* background: #111 !important; */
  box-sizing: content-box;
  border-left: none;
  display: inline-block;
  margin-left: -5px;
  background-color: #ff8e75;
  border-color: #ff8e75;
  min-width: 110px;
  margin-top: 7px;
}
