@import '../../styles/mixins.scss';
@import '../../styles/constants.scss';

footer {
  background-color: transparent;
  text-align: center;
  height: $footer-height;
  box-sizing: border-box;
  color: $footer-text-color;
  font-size: 16px;

  position: absolute;
  width: 100%;

  // fix visual artifact
  margin-top: -1px;

  &[data-with-icons='true'] {
    text-align: inherit;
    padding: 0 135px;
    clear: both;
  }
}

#footer-wrapper {
  &[data-with-icons='false'] {
    @include vertical-align();
  }

  &[data-with-icons='true'] {
    display: inline;
    line-height: $footer-height;
    float: left;

    & > div {
      display: inline;
      &:first-of-type {
        margin-right: 5px;
      }
    }
  }
}

#footer-icons {
  display: inline;
  overflow: hidden;
  line-height: $footer-height;
  float: right;

  img {
    height: 60px;
    vertical-align: middle;
    margin-right: 35px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1096px) {
  footer {
    &[data-with-icons='true'] {
      padding: 0 75px;
    }
  }

  #footer-icons {
    img {
      height: 40px;
      margin-right: 15px;
    }
  }

  #footer-wrapper {
    &[data-with-icons='true'] {
      display: block;

      @include vertical-align();

      & > div {
        display: block;
        line-height: initial;
      }
    }
  }
}

@media screen and (max-width: $tablet-width) {
  footer {
    &[data-with-icons='true'] {
      padding: 10px 40px !important;
      text-align: center;
    }
  }

  #footer-icons {
    line-height: initial;
    float: none;
    img {
      height: 45px;
    }
  }

  #footer-wrapper {
    margin-bottom: 15px;

    &[data-with-icons='true'] {
      transform: translateY(0%);
      float: none;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: auto;
      line-height: initial;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      width: 70%;

      & > div {
        text-align: center;
        display: inline;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  #footer-icons {
    img {
      height: 35px;
    }
  }
}

.sl-interactive-v2-body {
  footer {
    background-color: $footer-blue;
  }
}

[data-org='error'] {
  footer {
    position: absolute;
    bottom: 0;
  }
}

.sl-empty-layout {
  footer {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  #footer-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    &[data-with-icons='true'] {
      font-size: 15px;
    }
  }
}
