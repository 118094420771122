@import '../../styles/constants';

.sl-select-wrapper {
  position: relative;
  display: inline;

  &::after {
    content: '\25BC';
    content: '' \9;
    color: $dark-blue;
    font-size: 18px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
  }

  .sl-select {
    appearance: none;
    border-radius: $border-radius;
    border: 0;
    background-color: $lighter-blue;
    color: $dark-blue;
    font-weight: $component-font-weight;
    margin: $component-margin;
    padding: 13px 32px 13px 17px;
    line-height: 1.2;
    cursor: pointer;
    width: 100%;
    &--has-error {
      border: 2px solid $error;
    }

    &:focus {
      box-shadow: $component-box-shadow;
    }

    &--wide {
      width: 100%;
    }

    option {
      background-color: $white;
      background-color: $lighter-blue \9;
    }

    &__label {
      position: absolute;
      text-indent: -9999px;
    }
  }
}

// Don't display select arrow on IE
select::-ms-expand {
  display: none;
}

.dropdown-select-wrapper {
  position: relative;

  &::after {
    content: '\25BC';
    content: '' \9;
    color: rgb(22, 50, 88);
    font-size: 12px;
    pointer-events: none;
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    right: 14px;
  }
}
