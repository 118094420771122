@import '../../styles/constants.scss';

.signup-existing-account {
  &__body {
    max-width: $inner-content-width;
    background: $white;
    display: flex;
    flex-direction: column;
    color: $black;
    height: 100%;

    h1 {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: $font-size-form-title-small;
    }

    #signup-existing-account-footer {
      background-color: $white;
      color: $legally_gray;
      text-align: center;
      height: $footer-height;
      font-size: $font-size-extra-small;
      width: 100%;
      justify-self: flex-end;
      margin-top: auto;
      padding-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__forgot-password-link {
    text-decoration: none;
    color: $daylight-text;
    font-size: $font-size-small;
    font-weight: 500;
    align-self: center;
  }
}

@media screen and (max-width: $tablet-width-landscape) {
  // this doesn't actually _solve_ the problem so much as mask it. Old iOS does weird shit
  // with 100% heights and padding
  .signup-existing-account {
    &__body {
      height: unset;
    }
  }
}
