.sl-fitbit-sso {
  width: 100%;
  text-align: right;
  margin-bottom: 40px;
  text-decoration: none;

  &__inner {
    position: relative;
    display: block;
    vertical-align: middle;
    width: 140px;
    padding: 15px 0 15px 60px;
    font-size: 16px;
    font-weight: 700;
    margin: 16px auto;
    text-align: left;
    box-shadow: 0 2px 4px 1px rgba(85, 85, 85, 0.34);
    border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    border: 1px solid #c1c1c1;
    cursor: pointer;
    color: #fff;
    background: url(./img/fitbit-sso-logo.png) 15px 10px no-repeat;
  }
}
