@import '../../styles/constants';

.multi-product-header {
  height: $multiproduct-header-height;
  background: $white;
  width: 100%;

  &__content {
    margin: 0 auto;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0 $inner-content-padding;
  }

  &__logo-container {
    margin: auto;
  }

  &__logo {
    height: 50px;
    padding: 0 24px;
    &__sleepio {
      height: 45px;
    }
  }

  &__divider {
    height: 1px;
    background-color: #dfe1e6;
    width: 100%;
    margin: 0 auto;
  }

  &__subheader {
    font-size: $component-font-size;
    color: $black;
    margin: 16px auto 0;
  }

  &__logo-container {
    margin: auto;
    display: flex;
  }

  &__logo {
    height: 50px;
    padding: 0 24px;
    &__sleepio {
      height: 45px;
    }
  }

  &__divider {
    height: 1px;
    background-color: #dfe1e6;
    width: 100%;
    margin: 0 auto;
  }

  &__subheader {
    font-size: $component-font-size;
    color: $black;
    margin: 16px auto 0;
  }

  &__brand {
    color: $big-health-logo-blue;
    font-weight: 700;
  }
}

@media screen and (max-width: $tablet-width) {
  .multi-product-header {
    &__subheader {
      font-size: $component-font-size-tablet;
    }
  }
}

/* fix for mobile landscape inputs not reachable */
@media screen and (max-height: $mobile-height) {
  .multi-product-header {
    position: relative;
  }
}

@media screen and (max-width: $small-mobile-width) {
  .multi-product-header {
    &__logo {
      padding: 0 16px;
    }
  }
}
