@import '../../styles/constants';
.phone-number {
  label {
    display: none;
  }

  &__component {
    width: 327px;
    padding: 24px;
    border: 1px solid $input-outline-gray;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;

    @media screen and (max-width: $mobile-width-large) {
      width: 285px;
    }

    &--error {
      border: 1px solid $daylight-error-red;
    }

    &::placeholder {
      color: $legally_gray;
      font-weight: 400;
    }
  }

  &__message {
    visibility: hidden;

    &--error {
      padding-top: 4px;
      visibility: visible;
      color: $daylight-error-red;
    }
  }
}
