@import '../../styles/constants.scss';

.login-form {
  font-weight: 400;
  display: flex;
  flex-direction: column;

  &__error {
    background-color: $pink;
    color: $error-red;
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 8px;
    font-size: $font-size-extra-small;
  }

  input {
    padding: 24px;
    background: #fbfcfd;
    border: 1px solid #dfe1e6;
    border-radius: 8px;
    color: $daylight-text;
    font-size: $font-size-extra-small;
    margin-top: 8px;
  }

  &__email-input {
    margin-bottom: 32px;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    line-height: 135%;
    min-height: 24px;
  }

  &__termsConditions {
    margin: 16px 0;

    &__tc_item {
      display: flex;
      flex-direction: row;
      label {
        color: $legally_gray;
        font-size: $font-size-extra-small;
        display: flex;
        flex-direction: row;

        span {
          padding-top: 11px;
          padding-left: 8px;
        }
      }

      &__checkbox {
        min-width: 24px;
        min-height: 24px;
        background: #fbfcfd;
        border: 1px solid #dfe1e6;
        border-radius: 4px;
      }

      a {
        font-weight: 700;
        color: $daylight-link;
        &:hover {
          color: $light-blue;
        }
      }
    }
  }

  @media screen and (max-width: $tablet-width-landscape) {
    .login-form {
      &__termsConditions {
        &__tc_item {
          label {
            font-size: $font-size-small;
          }
          input {
            padding: unset;
          }
        }
      }
    }
  }

  // TODO: BTC - definitely want to raise most of this to a common component or mixin
  &__submit {
    width: 247px;
    min-height: 56px;
    margin: 16px 0;
    background: #000000;
    border-radius: 88px;
    color: white;
    align-self: center;
    cursor: pointer;
    border: none;
    font-size: $font-size-small;

    &:disabled {
      cursor: default;
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
