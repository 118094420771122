.sl-loader {
  width: 105px;
  height: 105px;
  background: url('./img/throbber-moon-transparent@2x.gif') no-repeat;
  background-size: 100%;
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
