@use 'sass:math';

@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';

@mixin multiproduct-background {
  background: none;
  background-color: $white;
  background-image: none;
}

.sl-flow.interactive {
  height: 100vh;
}

.sl-interactive-v2-body {
  .sl-header__text {
    display: none;
  }
  .sl-content {
    background-color: $dark-blue;
    background-image: url('./img/interactive-v2.svg');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .multiproduct-content {
    height: calc(100% - $multiproduct-header-height);
    padding: calc(#{$header-height} + #{$progress-wrapper-height}) 0 0 0; // based on sl-content
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  // Include inner-content as multiproduct component should NOT use .sl-inner-content class
  // due to custom CSS overwriting the .sl-inner-content class in Daylight
  .multiproduct-inner-content {
    @include inner-content;
    height: 100%;
  }

  .multiproduct-background {
    @include multiproduct-background;
  }
}

.sl-interactive-v2 {
  position: relative;

  h1,
  h2 {
    text-align: center;
    line-height: 1.2;
    margin-bottom: 18px;
    font-size: 40px;
  }

  div[data-disabled='true'] {
    opacity: 0.5;
  }

  &--header {
    $padding-vertically: 20px;
    $padding-horizontally: 30px;

    margin: 0 0 20px 0;
    padding: $padding-vertically $padding-horizontally;
    border-radius: 3px;

    $image-width: 72px;
    $image-height: 72px;
    $image-right-margin: $padding-horizontally;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .sl-interactive-v2--header-image {
      display: none;
    }

    .sl-interactive-v2--header-text {
      font-size: 19px;
      color: $white;
      vertical-align: middle;
      line-height: 24px;
      text-align: center;
      display: inline-block;
      width: 100%;

      b {
        font-weight: bold;
      }
    }

    &[data-cvs-style='true'] {
      background-color: $interactive-v2-light-blue;

      .sl-interactive-v2--header-image {
        background: url('./img/user.png') no-repeat left center;
        background-size: contain;
        width: $image-width;
        height: $image-height;
        margin-right: $image-right-margin;
        display: inline-block;
        vertical-align: middle;
      }

      .sl-interactive-v2--header-text {
        font-size: 19px;
        color: $dark-blue;
        width: calc(100% - #{$image-width} - #{$image-right-margin});
        vertical-align: middle;
        line-height: 24px;
        b {
          font-weight: bold;
        }
      }
    }
  }
  &--error {
    background-color: $pink;
    color: $error-red;
    padding: 20px;
    margin: 40px 0 40px 0;
    border-radius: 4px;
    font-size: 16px;
  }

  .sl-interactive--field-label {
    margin-bottom: 6px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 23px;

    &[data-ellipsis='true'] {
      white-space: nowrap;
    }
    &[data-small-font='true'] {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .sl-interactive--help-text {
    font-size: 14px;
    opacity: 0.7;
    line-height: 1.2;
    display: block;
    margin: 2px 0 30px 0;
  }

  .sl-password-hint {
    text-align: center;
    padding: 0 20px;
    line-height: 1.2;
    font-size: 16px;
  }

  form {
    margin-bottom: $header-height;
    padding: 40px 100px;
    background-color: #355e86;
    box-sizing: border-box;
    border-radius: 4px;

    .sl-button-wrapper {
      margin-bottom: 30px;
    }

    .sl-interactive--radio {
      font-size: 20px;
      .sl-radio label {
        width: auto;
        padding-right: 18px;
        font-size: 18px;
      }
    }

    .sl-container-text-input {
      margin-bottom: 20px;
      &--has-error {
        .sl-input-text {
          border: 3px solid $error-red;
        }
      }
    }

    .sl-input-text__error,
    .sl-input-checkbox__error,
    .sl-error {
      color: $error-red;
      background-color: $pink;
      border: 2px solid $error-red;
      border-radius: 2px;
      padding: 5px;
      font-size: 15px;
    }

    .col-left,
    .col-right {
      width: 48%;
    }

    .col-left {
      float: left;
    }

    .col-right {
      float: right;
    }

    .email-password {
      clear: both;
    }
  }

  .sl-interactive--voucher-code,
  .sl-interactive--employee-id,
  .sl-interactive--zip-code,
  .sl-interactive--postal-code,
  .sl-interactive--action {
    margin-top: 10px;
  }

  .sl-interactive--employee-id-help-text {
    color: $error;
    white-space: pre-wrap;
    line-height: 1.2;
    margin-bottom: 20px;
    display: block;

    @media screen and (max-width: $tablet-width) {
      text-align: center;
    }
  }

  .sl-interactive--eligibility-top-error {
    color: $error;
    text-align: center;
    margin-bottom: 20px;
    display: block;
  }

  .sl-interactive--additional-instructions-text {
    hr {
      width: calc(65% - 4px);
      border-color: $white;
      border-width: 1px;
      border-style: solid;
    }

    .html-container {
      color: $interactive-light-blue;
      margin-top: 35px;
      margin-bottom: 20px;
      display: block;
    }
  }

  .sl-interactive--action {
    margin-top: 20px;
  }

  .sl-interactive--email-opt_in {
    margin-bottom: 25px;
    font-size: 18px;

    h2 {
      margin-bottom: 15px;
    }
  }

  .sl-interactive--radio {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;

    h2 {
      text-align: left;
      margin-bottom: 1px;
    }

    .sl-radio label {
      float: left;
      width: 30%;
      @media screen and (max-width: $tablet-width) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .sl-interactive--text {
    width: 100%;
    vertical-align: top;
  }

  .sl-interactive--date {
    margin-bottom: 15px;
    margin-top: 5px;

    .sl-date-select--col {
      $date-select-margins: 5%;

      box-sizing: content-box;
      padding-right: $date-select-margins;
      margin-right: 0;

      .sl-select-wrapper .sl-select {
        background: $white;
        &--has-error {
          border: 3px solid $error-red;
        }
      }

      &.sl-date-type--month {
        width: calc(37.5% - #{$date-select-margins});
      }

      &.sl-date-type--day {
        width: 25%;
      }

      &.sl-date-type--year {
        width: calc(37.5% - #{$date-select-margins});
        padding: 0;
      }
    }
  }

  .sl-interactive--sso {
    text-align: center;
    margin-top: 15px;
  }

  .sl-interactive--headers {
    h1 {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 35px;
    }
  }

  .sl-interactive--clinical-governance {
    text-align: center;
    padding-top: 12px;

    span {
      font-size: 16px;
    }

    a {
      text-decoration: underline;
    }
  }

  .sl-interactive--data-storing {
    padding-top: 20px;

    label,
    input {
      cursor: pointer;
    }

    a {
      text-decoration: underline;
    }
  }

  .sl-interactive-eligibility {
    p {
      margin: 10px 0 5px 0;
    }
  }
  .sl-button {
    background-color: $light-green;
    color: $dark-blue;
    border: none;
    text-transform: none;
    font-weight: 500;
    &:hover {
      background: $dark-green;
      border-color: $dark-green;
    }
  }

  .sl-input-tooltip .sl-tooltip-text-wrapper {
    left: calc(-100% + 48px);
    transform: translateY(-58%);
    padding-left: 30px;
    right: auto;
    .sl-tooltip-text {
      color: $dark-blue;
      background-color: $yellow;
      border-radius: 4px;

      &:before {
        $arrow-width: 11px;
        $arrow-height: 22px;

        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: math.div($arrow-height, 2) $arrow-width
          math.div($arrow-height, 2) 0;
        border-color: transparent $yellow transparent transparent;
        position: absolute;
        left: 20px;
        top: calc(50% - 11px);
      }
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-interactive-v2-body {
    .multiproduct-content {
      padding: 80px 0 0 0;
    }
  }

  .sl-interactive-v2 {
    .sl-interactive-header {
      p {
        padding: 0 0 0 70px;
        font-size: 18px;
      }
    }
    .sl-date-select--col {
      display: inline-block;
    }
    form {
      padding: 0;
      margin-bottom: 0;
      background: none;
      h1 {
        font-size: 24px;
      }

      &--help-text,
      .sl-password-hint {
        font-size: 14px;
      }
      .sl-interactive--radio {
        .sl-radio label {
          width: 100%;
        }
      }
    }
    .sl-input-tooltip {
      .sl-tooltip-text-wrapper {
        left: auto;
        transform: translateY(calc(-100% - 60px));
        padding: 0;
        right: calc(-100% + 14px);
        display: none;
        .sl-tooltip-text {
          z-index: 1;

          &:before {
            bottom: 0;
            transform: rotate(-90deg);
            margin-bottom: -15px;
            right: 15px;
            top: auto;
            left: auto;
          }
        }
      }
      &.active {
        .sl-tooltip-text-wrapper {
          display: block;
        }
      }
    }
  }
  .sl-interactive-v2-body {
    .sl-content {
      background: none;

      &.multiproduct-background {
        @include multiproduct-background;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .sl-interactive-v2 {
    &--header {
      $padding-vertically: 18px;
      $padding-horizontally: 15px;

      padding: $padding-vertically $padding-horizontally;
      background-color: $interactive-v2-light-blue;

      $image-width: 58px;
      $image-height: 58px;
      $image-right-margin: $padding-horizontally;

      .sl-interactive-v2--header-image {
        background: url('./img/user.png') no-repeat left center;
        background-size: contain;
        width: $image-width;
        height: $image-height;
        margin-right: $image-right-margin;
      }

      .sl-interactive-v2--header-text {
        font-size: 16px;
        color: $dark-blue;
        text-align: center;
        width: 100%;
      }
    }
    .sl-interactive--signup-form {
      .sl-interactive--name {
        width: 100%;
      }
    }
  }

  .sl-interactive-v2 .sl-tooltip-text-wrapper {
    right: auto;
    .sl-input-tooltip {
      position: static;
      padding: 0;
      .sl-tooltip-text {
        width: 100%;
        box-sizing: border-box;

        .sl-employee-tooltip-text {
          margin: 20px 20px 0;
        }
      }

      span {
        z-index: 1;
        color: white;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        background: $dark-blue;
        text-align: center;
        line-height: 30px;
        top: 28px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
}

.error-color {
  color: $error-red;
  font-weight: 700;

  &:hover {
    color: $error-red;
    opacity: 0.6;
  }
}

.multiproduct-backgrounds {
  background: none;
  background-color: white;
  background-image: none;
}
