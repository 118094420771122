@import '../../styles/constants';

.sl-progress-wrapper {
  height: $progress-wrapper-height;
  width: 100%;
  margin: 90px auto 0;
  background-color: #335c85;
  box-sizing: border-box;
  position: fixed;
  z-index: 9;

  &[data-sticky-top='true'] {
    margin-top: 0;
  }
}

.sl-progress {
  height: 100%;
  background-color: #ffffff;
}

.sl-progress-dots {
  display: none;

  span {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: $light-gray;
    display: inline-block;
    margin-right: 6px;

    &[data-active='true'] {
      background-color: $not-quite-black;
    }
  }
}

@media screen and (max-width: $tablet-width) {
  .sl-progress-wrapper {
    margin-top: 70px;
  }
}
/* fix for mobile landscape inputs not reachable */
@media screen and (max-height: $mobile-height) {
  .sl-progress-wrapper {
    position: relative;
    margin-top: 0;
  }
}
