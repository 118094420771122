$placeholder-color: #757575;

$light-blue: #355e86;
$dark-blue: #003366;
$light-green: #4db400;
$lighter-green: #58c904;
$darker-green: #3f8f03;
$dark-green: #006600;
$white: #ffffff;
$light-grey: #dfe1e6;
$legally_gray: #6a717d;

$red: #d0021b;
$light-red: #ffe4e4;

$form-background: $light-blue;
$button-hover-color: $lighter-green;
$button-click-color: $darker-green;

$input-border-color: $light-grey;

/* sleepio */
$pale_gray: #f7f6f5;
$sleepio_navy: #163258;
$salmon: #ff8e75;
$primary-navy: #082143;

/* daylight */
$daylight-link: #2467b0;
$daylight-text: #363334;
$daylight-background: #f6f6f6;
$daylight-button: #111111;
