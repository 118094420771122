@import '../../styles/constants';

.sl-date-wrapper::after {
  content: '';
  clear: both;
  display: block;
}

.sl-date-select--col {
  display: inline-block;
  width: 32%;
  margin-right: 2%;
  box-sizing: border-box;

  &:first-child {
    width: 34%;
  }

  &:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .sl-date-select--col {
    display: block;
    width: 100%;
    margin-top: 8px;

    &:first-child {
      width: 100%;
      margin-top: 0;
    }
  }
}
