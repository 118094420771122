/* Colors */
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0.98);
$black: #000000;
$interactive-light-blue: #ade0f9;
$interactive-top-error-red: #e58085;
$interactive-v2-light-blue: #d1e7f3;
$light-blue: #29c3e5;
$lighter-blue: #29c3e5;
$header-blue: #001848;
$footer-blue: #001848;
$dark-blue: #003366;
$dark-gray: #74777b;
$not-quite-black: #010101;
$light-gray: #dddddd;
$light-green: #0a0;
$other-light-green: #4db400;
$yellow: #fff4ad;
$dark-green: #006600;
$error: #fd717f;
$pink: #ffe4e4;
$error-dark: #900808;
$error-red: #d0021b;
$box-answer: #daf2f9;
$box-answer-selected: #94e1f2;
$footer-text-color: #9aa7bb;
$big-health-logo-blue: #33bad3;
$legally_gray: #6a717d;
$input-outline-gray: #dfe1e6;

/* daylight colors */
$orange: #f69174;
$daylight-link: #2467b0;
$daylight-text: #363334;
$daylight-background: #f6f6f6;
$daylight-button: #111111;
$daylight-error-red: #b92d0f;
$daylight-success-green: #008275;
/* Content constants */
$max-content-width: 1440px;
$inner-content-width: 600px;
$inner-content-padding: 20px;
$tablet-width: 700px;
$tablet-width-landscape: 850px;
$mobile-width-large: 475px;
$mobile-width: 375px;
$mobile-height: 365px;
$header-height: 90px;
$header-height-tablet: 70px;
$progress-wrapper-height: 4px;
$footer-height: 110px;
$small-mobile-width: 320px;
$multiproduct-header-height: 150px;

/* Component constants */
$component-font-size: 20px;
$component-font-size-tablet: 16px;
$component-font-weight: 500;

$component-margin: 0;
$component-padding: 15px 17px;
$component-box-shadow: 0 0 2px 2px $dark-blue;

/* Other constants */
$border-radius: 3px;

/* Fonts Constants */
$font-size-form-title: 30px;
$font-size-form-title-small: 21px;
$font-size-standard: 20px;
$font-size-small: 18px;
$font-size-extra-small: 16px;
