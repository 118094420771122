@import '../../styles/constants';

$preference-center-blue: #031a45;

.center {
  align-items: center;
  text-align: center;
}
.w-100 {
  width: 100%;
}
.header-font {
  font-size: 30px;
  line-height: 34px;
}
.text-font {
  font-size: 16px;
  line-height: 18px;
}
.large-top-margin {
  margin-top: 30px;
}
.self-end {
  justify-self: end;
}
.header-margin {
  margin-top: 140px;
}
.horizontal-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.preference-container {
  display: flex;
  flex-direction: column;
  background-color: $preference-center-blue;
  background-image: url('../../svgs/sleepio-mountains.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: 555px) {
    background-size: 555px;
  }
  .sl-header {
    background-color: $preference-center-blue;
  }
  a {
    text-decoration: none;
  }
  h3 {
    max-width: 572px;
  }
}
footer {
  position: relative;
}

/* This is for daylight unique styles */
.daylight.preference-container {
  background-color: $orange;
  background-image: none;
}

.daylight {
  a {
    color: black;
  }
  h3 {
    color: black;
  }
  p {
    color: black;
  }
}
