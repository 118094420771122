@import '../../../../styles/constants';

.sl-interactive-v2 {
  [data-input-name='first_name'] + [data-input-name='middle_name'] {
    width: 30%;
    display: inline-block;
    vertical-align: top;
  }

  [data-input-name='first_name'] {
    width: 47.5%;
    display: inline-block;
    margin-right: 5%;

    &[shorten='true'] {
      width: 65%;
      margin-right: 5%;
    }
  }

  [data-input-name='last_name'] {
    width: 47.5%;
    display: inline-block;
    &[wide='true'] {
      width: 100%;
    }
  }

  .sl-interactive--employee-id-above-help-text {
    $check-mark-size: 32px;
    $check-mark-margin: 12px;
    margin: 15px 0;

    p {
      white-space: pre-wrap;
      font-size: 16px;
      text-align: center;
      padding: 15px;
      background: $interactive-v2-light-blue;
      color: $dark-blue;
      opacity: 1;
      margin: 15px 0;
      border-radius: 3px;
    }

    span {
      width: calc(50% - #{$check-mark-size}/ 2 - #{$check-mark-margin});
      display: inline-block;

      &.sl-interactive--employee-id-line {
        border-bottom: 1px solid $white;
        transform: translateY(-50%);
        vertical-align: middle;
        height: 0;
        opacity: 0.6;
      }

      &.sl-interactive--employee-id-check {
        color: #355e86;
        height: $check-mark-size;
        width: $check-mark-size;
        text-align: center;
        line-height: $check-mark-size;
        background: $white;
        border-radius: 100%;
        margin: 0 $check-mark-margin;
        opacity: 0.6;
      }
    }
  }
}
