@import '../../styles/constants';
body {
  &[data-org='unsubscribe'] {
    .sl-page {
      background-color: $white;
      margin-top: 0;
      margin-bottom: 0;
      padding: 150px 0px 20px;
      box-sizing: border-box;
      min-height: calc(100vh - #{$footer-height}) !important;
      height: auto !important;

      @media screen and (max-width: $tablet-width) {
        min-height: calc(100vh - #{$footer-height}) !important;
        height: auto !important;
      }
      h1,
      h2,
      p {
        text-align: center;
        color: $dark-blue;
        font-weight: bold;
        line-height: 1;
        margin: 0 10px;
      }

      p {
        margin-top: 30px;
        font-size: 18px;
      }

      h2 {
        margin-top: 10px;
      }
    }
  }
}
